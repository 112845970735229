@tailwind base;
@tailwind components;
@tailwind utilities;

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type='search']::placeholder {
  @apply text-03;
}

.popper[data-popper-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
}
